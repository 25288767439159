<template>
  <div :class="['placeholder-image', 'ratio', `ratio-${ratio}`]">
    <div class="image-wrapper">
      <b-icon class="image-icon" icon="image-placeholder" :style="`height: ${size}%;`"/>
    </div>
  </div>
</template>

<script>
import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

export default {
  name: 'PlaceholderImage',
  props: {
    ratio: {
      type: String,
      default: '1x1',
      validator: value => SCSS_VARIABLES.vars['ratio-keys'].includes(value)
    },
    size: {
      type: [Number, String],
      default: 100,
      validator: value => Number(value) >= 0
    }
  }
}
</script>

<style lang="scss">
$placeholderimage-color: $gray-300 !default;

.placeholder-image {
  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .image-icon {
      width: auto;
      color: $placeholderimage-color;
    }
  }
}
</style>
